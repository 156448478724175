<template>
  <div
    class="relative"
    :class="[{ 'input-error-active': !!errorMessage, 'input-success-active': meta?.valid }, margin]"
  >
    <SvgIcon
      v-if="currency"
      :icon="currency"
      class="h-4 absolute z-10 left-3 block top-4 mt-0.5"
      width="16"
      height="16"
    />

    <input
      :id="name"
      v-model="inputValue"
      class="block relative w-full bg-black/50 border-slate-100/20 rounded-lg py-3 px-4 border-2 outline-none focus:border-white/40 focus:ring-0 autofill:bg-slate-900 transition-all disabled:text-slate-100"
      :class="[
        currency ? 'pl-8 pr-4': 'px-4',
        !showValidationTick ? 'px-4' : 'pr-10'
      ]"
      :name="name"
      :type="type"
      :autocomplete="autocomplete"
      :step="step"
      :placeholder="placeholder"
      :disabled="disabled"
      @blur="handleBlur"
    >

    <span
      v-if="showValid"
      class="icon-ico-tick text-green text-2xl top-2 right-2 absolute z-10 transition-all pointer-events-none duration-300 -translate-y-2 opacity-0 input-success:opacity-100 input-success:translate-y-0"
    />
    <span
      v-if="showInvalid"
      class="icon-ico-x rounded-lg bg-slate-900 px-2.5 text-sm top-4 right-2 absolute z-10 transition-all pointer-events-none duration-300 translate-y-2 opacity-0 input-error:opacity-100  input-error:translate-y-0"
      :class="errorFriendly ? 'text-blue-100' : 'text-red'"
    />

    <p
      v-if="errorMessage && !hideErrorMessages"
      class="w-full inline-block text-sm mt-1 leading-tight"
      :class="[
        errorFriendly ? 'text-blue-100' : 'text-red',
        centerAlignErrors ? 'text-center' : ''
      ]"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<script setup>
import { useField } from 'vee-validate';

const props = defineProps({
  type: {
    type: String,
    default: 'text',
  },
  value: {
    type: [String, Number,],
    default: '',
  },
  autocomplete: {
    type: String,
    default: 'on',
  },
  step: {
    type: String,
    default: '',
  },
  maxDecimalPlaces: {
    type: Number,
    default: 5,
  },
  name: {
    type: String,
    required: true,
  },
  margin: {
    type: String,
    default: 'mt-3 pb-3',
  },
  placeholder: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  optional: {
    type: Boolean,
    default: false,
  },
  showValidationTick: {
    type: Boolean,
    default: false,
  },
  hideErrorMessages: {
    type: Boolean,
    default: false,
  },
  hideValidationCross: {
    type: Boolean,
    default: false,
  },
  errorFriendly: {
    type: Boolean,
    default: false,
  },
  centerAlignErrors: {
    type: Boolean,
    default: false,
  },
  currency: {
    type: String,
    default: '',
  },
});

// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validte needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = ref(props.name);
const maxDecimalPlaces = ref(props.maxDecimalPlaces);
const inputType = ref(props.type);

const showValid = computed(() => ((props.optional && inputValue.value) || (!props.optional)) && props.showValidationTick);
const showInvalid = computed(() => ((props.optional && inputValue.value) || (!props.optional)) && !props.hideValidationCross);

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const {
  value: inputValue,
  errorMessage,
  handleBlur,
  meta,
} = useField(name, undefined, {
  initialValue: props.value,
});

watch(
  () => props.value,
  (newVal) => {
    inputValue.value = newVal;
  }
);

watch(
  () => inputValue.value,
  (newValue) => {
    if (!newValue) {
      inputValue.value = newValue;
      return;
    }
    const decimalCount = newValue.toString().split('.')[1]?.length;

    if (inputType?.value?.toLowerCase() === 'number' && decimalCount > maxDecimalPlaces.value) {
      inputValue.value = useNuxtApp().$truncateNumber(newValue, maxDecimalPlaces.value);
    } else {
      inputValue.value = newValue;
    }
  }
);
</script>
